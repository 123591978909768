/**
 * Specific values for the PROD environment.
 */
const api_endpoint = 'https://api.newjobapplications.com';

const environment = {
  env: 'prod',
  number_of_jobs: 20,
  pushnami_id: '5f847bae9d8ce5001254e938',
  recommend_search_api: `${api_endpoint}/reco/search`,
  recommend_api: `${api_endpoint}/reco/related/query/to/query/`,
  related_api: `${api_endpoint}/reco/search/by/job/`,
  subscriber_api: `${api_endpoint}/subscription`,
  talroo_search_api: `${api_endpoint}/reco/search`,
  search_volume_by_city_api: `${api_endpoint}/usjobs/searchvolumebycity`,
  api_key: 'C6uaMgoHvC3P5xs6uVyuQIZ9jAH47km2AfmkFS0e',
  tenant_id: '2pehg2bjm9aktqad4lj4qpd9lq',
  default_api_format: 'json',
  default_api_link: 1,
  default_api_logo: 1,
  j2c_web_api_endpoint: 'https://te59819vs0.execute-api.us-east-1.amazonaws.com/prod/proxies',
  j2c_web_api_bearer_token: 'FEHfiZFOincvDBx5jMKYdump0xdw00OtZHKaicLk9NeWhS2QK67fXO9nbMzUAVEi',
  job_list_ads: [
    {
      type: 'adSense',
      client: 'ca-pub-9628061608401614',
      slot: '9693794091',
      format: 'link',
      responsive: 'true',
      style: { display: 'block' }
    },
    // related search
    {
      type: 'pop',
      id: '90f84f2a-d5e8-4187-83e2-8841be4622ed',
      cid: 'Y3VzdG9tZXJfaWQ6Mjg0MQ'
    },
    // company
    {
      type: 'pop',
      id: '5f93ea18-f11b-4081-9111-34b5bf3cf857',
      cid: 'Y3VzdG9tZXJfaWQ6Mjg0MQ'
    },
    // industry
    {
      type: 'pop',
      id: '289657d6-6e40-497f-9da2-d796ad43051c',
      cid: 'Y3VzdG9tZXJfaWQ6Mjg0MQ'
    },
    // related position
    {
      type: 'pop',
      id: '29e66d39-bdac-4044-b97f-7db3ec19f1a3',
      cid: 'Y3VzdG9tZXJfaWQ6Mjg0MQ'
    },
    { type: 'none' }
  ],
  reg_path_ads: [
    { type: 'none' },
    {
      type: 'pop',
      id: '8b56c821-5c90-4978-a109-df3e646fb925',
      cid: 'Y3VzdG9tZXJfaWQ6Mjg0MQ',
      title: 'Positions you might like'
    }
  ],
  talroo_terms_page: 'https://www.talroo.com/terms/?utm_source=thejobwizard.com&utm_campaign=jobs2careers&utm_content=terms',
  talroo_privacy_page: 'https://www.talroo.com/privacy/?utm_source=thejobwizard.com&utm_campaign=jobs2careers&utm_content=privacy',
  talroo_ccpa_dns_page: 'https://www.talroo.com/ccpa-dns/?utm_source=thejobwizard.com&utm_campaign=jobs2careers&utm_content=dnsmpi',
  talroo_ccpa_privacy_page: 'https://www.talroo.com/ccpa-privacy/?utm_source=thejobwizard.com&utm_campaign=jobs2careers&utm_content=ccpa-privacy',
  job_display: {
    use_pop: false,
    ad_cid: null,
    ad_id: null,
  },
};

export default environment;
